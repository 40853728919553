import {
  get,
  keyBy,
} from 'lodash';
import {
  ELSAdobeAnalyticService,
  ELSCommonUIConstants,
  ELSLoggingService
} from '@els/els-ui-common-react';
import {
  fetchAssessmentSubmissions as fetchAssessmentSubmissionsService,
  fetchOsmosisToken,
  fetchUsers as fetchUsersService,
  fetchVitalSource
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.utilities';
import {
  fetchAppLinkData as fetchAppLinkDataService,
  fetchHashLink,
  getExternalAppRedirectUrl,
  getLinkNavigation,
} from '../../apis/eols-app-link/eols-app-link.utilities';
import {
  fetchCourseSection as fetchCoursePlanningCourseSectionService,
  updateCourseSection as updateCourseSectionService,
} from '../../apis/eols-course-management-service/eols-course-management-service.utilities';
import {
  fetchCourseSection as fetchCourseSectionService,
  fetchUserCourseSections,
} from '../../apis/eols-course-crud/eols-course-crud.utilities';
import { reduxStateDomains } from '../redux.constants';
import {
  UserDto,
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  AppLinkData,
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { LiteCourseSectionDto } from '../../apis/eols-course-management-service/eols-course-management-service.dtos';
import {
  fetchCrosswalkUser as fetchCrosswalkUserService,
  fetchUser as fetchUserService
} from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import {
  fetchGroupedFeatureFlags,
  fetchGroupFeatureFlag,
  postGroupFeatureFlag
} from '../../apis/eols-features-api/eols-features-api.utilities';
import {
  deleteUserHistoryByUserId,
  fetchUserHistoryByCourseByStateKey as fetchUserHistoryByCourseByStateKeyService,
  postUserHistory as postUserHistoryService,
} from '../../apis/eols-user-crud/eols-user-crud.utilities';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  CoursewareUserHistoryStateKey,
  EolsUserHistoryDto,
  EolsUserHistoryResponseDto,
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import { studySelectors } from './studentStudy.selectors';
import {
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  fetchPrimaryTaxonomy,
  fetchTaxonomy as fetchTaxonomyService
} from '../../apis/eols-taxonomy/eols-taxonomy.utilities';
import {
  AdobeAnalyticsActionProps,
  StudentStudyStore,
  NavigateToApp
} from './studentStudy.models';
import {
  AssessmentDto,
  AssessmentSubmissionDto,
  AssignmentDto,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  fetchAssessmentByAssignmentIdAndUserId as fetchAssessmentByAssignmentIdAndUserIdService,
  fetchAssessmentById,
  fetchAssessmentsByAssignmentIdAndUserId,
  fetchAssignment,
  fetchAssignmentResults,
  fetchAssignments as fetchAssignmentsService,
  putAssignment as putAssignmentService,
} from '../../apis/eols-assessment-service/eols-assessment-service.utilities';
import {
  SystemType
} from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import {
  fetchLessonTopics,
  fetchResourceInfo
} from '../../apis/ocs-api-service/ocs-api-service.utilities';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { AppLinkOutConfig } from '../../apis/eols-app-link/eols-app-link.models';
import { fetchUserEngagementReport as fetchUserEngagementReportService } from '../../apis/eols-user-engagement/eols-user-engagement.utilities';
import { EolsUserEngagementDto } from '../../apis/eols-user-engagement/eols-user-engagement.dtos';
import { Messages } from '../../translations/message.models';
import {
  GroupActivityDto
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import {
  AnalyticsAction,
  AnalyticsActionProps,
  IncludeAiChatPropsMap
} from '../../models/analytics.models';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import {
  fetchStudentStudyAssessments
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import {
  fetchDeepLinkData,
  postDeepLink
} from '../../apis/eols-lms-config-service/eols-lms-config-service.utilities';
import { DeepLinkPostDto } from '../../apis/eols-lms-config-service/eols-lms-config-service.dtos';
import { fetchAnalyticsContext } from '../../apis/learning-analytics-service/learning-analytics-service.utilities';
import { LearningAnalyticsRequestDto } from '../../apis/learning-analytics-service/learning-analytics-service.dtos';
import {
  getAssessmentSubmissionsMap,
  shouldGetNewOsmosisToken
} from '../redux.utilities';
import {
  getRandomABTestFlavor,
  getActiveABTestFlags,
} from '../../utilities/featureFlag.utilities';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../../apis/eols-features-api/eols-features-api.dtos';
import { stripNilProps } from '../../utilities/common.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import {
  adminFetchChatEntry,
  adminFetchChatEntries,
  adminPrompt,
  adminSearchVector,
  fetchAndPostProcessChatEntryReferences,
  fetchAndPostProcessChatEntryReferencesV2,
  fetchChatEeoIsbns,
  fetchChatEntitlements,
  fetchChatEntries,
  fetchChatEntry,
  fetchChatEntryTraces,
  fetchChatEvaluations,
  fetchChatPerformance,
  fetchChats,
  fetchRemediationRecommendationItems,
  postChat,
  postChatEntry,
  postChatEntryShells,
  postChatEvaluation,
  postChatPrompt,
  putChat,
  putChatEvaluation,
  adminFetchChatEntryTraces,
  adminFetchChat,
  fetchStudentThemesStatus,
  postStudentThemes,
  putPostProcessChatEntryReferences,
  fetchRemediationAssignment,
  fetchRemediationRecommendations,
  fetchRemediationRecommendationAttempts,
  fetchQuizSessionByRecommendationAttemptId,
  postRemediationRecommendationAttempt,
  postRaaSQuizSession
} from '../../apis/florence-facade/florence-facade.utilities';
import {
  ChatDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  DehydratedChatEntryDto,
  LlmPromptDto,
  NewQuizSessionDto,
  RecommendationAttemptDto,
  VectorSearchDto
} from '../../apis/florence-facade/florence-facade.dtos';

const fileName = 'student-study.actions';

export interface UserParams {
  userId: string;
  courseId: string;
  roleId: string;
  isbns: string;
}

export const studentStudyActionType = {
  FETCH_STUDENT_STUDY_ASSESSMENTS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_STUDENT_STUDY_ASSESSMENTS`,
  DELETE_USER_HISTORY_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/DELETE_USER_HISTORY_SUCCESS`,
  FETCH_USER_HISTORY_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USER_HISTORY_SUCCESS`,
  FETCH_COURSE_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_COURSE_SUCCESS`,
  FETCH_GROUPED_FEATURE_FLAGS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_GROUPED_FEATURE_FLAGS_SUCCESS`,
  FETCH_FEATURE_ISBNS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_FEATURE_ISBNS`,
  FETCH_LINK_DATA_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_LINK_DATA_SUCCESS`,
  FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS`,
  FETCH_USER_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USER_SUCCESS`,
  FETCH_USERS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USERS_SUCCESS`,
  POST_COURSE_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/POST_COURSE_SUCCESS`,
  REQUEST_ERROR: `${reduxStateDomains.STUDENTSTUDY_STATE}/REQUEST_ERROR`,
  REQUEST_START: `${reduxStateDomains.STUDENTSTUDY_STATE}/REQUEST_START`,
  REQUEST_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/REQUEST_SUCCESS`,
  RESET_STORE: `${reduxStateDomains.STUDENTSTUDY_STATE}/RESET_STORE`,
  RESET_STATE_ON_LAUNCH: `${reduxStateDomains.STUDENTSTUDY_STATE}/RESET_STATE_ON_LAUNCH`,
  RESTORE_STATE: `${reduxStateDomains.STUDENTSTUDY_STATE}/RESTORE_STATE`,
  SET_IS_COURSE_OWNER_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_IS_COURSE_OWNER_SUCCESS`,
  SET_ISBNS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_ISBNS`,
  SET_MESSAGES: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_MESSAGES`,
  SET_USER: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_USER`,
  SET_REGISTERED_TOKEN: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_REGISTERED_TOKEN`,
  FETCH_CATALOG_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_CATALOG_SUCCESS`,
  FETCH_PRIMARY_TAXONOMY_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_PRIMARY_TAXONOMY_SUCCESS`,
  FETCH_ASSIGNMENT_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_ASSIGNMENT_SUCCESS`,
  DELETE_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/DELETE_SYLLABUS_ITEMS_SUCCESS`,
  FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_ASSIGNMENTS_SUCCESS`,
  FETCH_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_SYLLABUS_ITEMS_SUCCESS`,
  POST_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/POST_SYLLABUS_ASSIGNMENT_SUCCESS`,
  POST_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/POST_SYLLABUS_ITEMS_SUCCESS`,
  PUT_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/PUT_SYLLABUS_ASSIGNMENT_SUCCESS`,
  PUT_SYLLABUS_ITEMS_BATCH_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/PUT_SYLLABUS_ITEMS_BATCH_SUCCESS`,
  PUT_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/PUT_ASSIGNMENTS_SUCCESS`,
  SET_CHECKED_SYLLABUS_ITEMS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_CHECKED_SYLLABUS_ITEMS`,
  SET_SYLLABUS_FOLDER_INFO: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_SYLLABUS_FOLDER_INFO`,
  SET_IS_BATCH_EDIT_MODE: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_IS_BATCH_EDIT_MODE`,
  SET_IS_DRAG_DROP_MODE: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_IS_DRAG_DROP_MODE`,
  UPDATE_COURSE_BUILDER_STATE: `${reduxStateDomains.STUDENTSTUDY_STATE}/UPDATE_COURSE_BUILDER_STATE`,
  FETCH_EOLS_USER_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_EOLS_USER_SUCCESS`,
  FETCH_CROSSWALK_USERS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_CROSSWALK_USERS_SUCCESS`,
  FETCH_EVOLVE_USER_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_EVOLVE_USER_SUCCESS`,
  POST_USER_HISTORY_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/POST_USER_HISTORY_SUCCESS`,
  SET_EBOOK_FILTER: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_EBOOK_FILTER`,
  HANDLE_EBOOK_FILTER_CHANGE: `${reduxStateDomains.STUDENTSTUDY_STATE}/HANDLE_EBOOK_FILTER_CHANGE`,
  HANDLE_PRODUCT_FILTER_CHANGE: `${reduxStateDomains.STUDENTSTUDY_STATE}/HANDLE_PRODUCT_FILTER_CHANGE`,
  HANDLE_RESOURCE_STATUS_FILTER_CHANGE: `${reduxStateDomains.STUDENTSTUDY_STATE}/HANDLE_RESOURCE_STATUS_FILTER_CHANGE`,
  HANDLE_RESOURCE_TYPE_FILTER_CHANGE: `${reduxStateDomains.STUDENTSTUDY_STATE}/HANDLE_RESOURCE_TYPE_FILTER_CHANGE`,
  HANDLE_RESOURCE_GRADING_FILTER_CHANGE: `${reduxStateDomains.STUDENTSTUDY_STATE}/HANDLE_RESOURCE_GRADING_FILTER_CHANGE`,
  SET_ASSESSMENT_START_TIME_MAP: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_ASSESSMENT_START_TIME_MAP`,
  SET_APP_LINK_COOKIES: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_APP_LINK_COOKIES`,
  UPDATE_COLLAPSED_FOLDER_IDS: `${reduxStateDomains.STUDENTSTUDY_STATE}/UPDATE_COLLAPSED_FOLDER_IDS`,
  FETCH_SKILL_STATIC_DATA: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_SKILL_STATIC_DATA`,
  FETCH_SKILL_SUBMISSION_RECORD: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_SKILL_SUBMISSION_RECORD`,
  SET_STORE_PROPS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_STORE_PROPS`,
  FETCH_COURSE_COPY_PREVIEW_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_COURSE_COPY_PREVIEW_SUCCESS`,
  SET_HESI_FOCUS_CHAPTER_FILTER: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_HESI_FOCUS_CHAPTER_FILTER`,
  SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS`,
  SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS`,
  FETCH_USER_ENGAGEMENT_REPORT_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USER_ENGAGEMENT_REPORT_SUCCESS`,
  CLEAR_ALL_FILTERS: `${reduxStateDomains.STUDENTSTUDY_STATE}/CLEAR_ALL_FILTERS`,
  FETCH_MODULE_SEQUENCE_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_MODULE_SEQUENCE_SUCCESS`,
  POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS`,
  FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID`,
  FETCH_GROUP_ACTIVITY_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_GROUP_ACTIVITY_SUCCESS`,
  SET_ENABLE_DEEP_LINK: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_ENABLE_DEEP_LINK`,
  SET_HAS_RUN_AUTHESS_HEALTH_CHECK: `${reduxStateDomains.STUDENTSTUDY_STATE}/SET_HAS_RUN_AUTHESS_HEALTH_CHECK`,
  FETCH_OSMOSIS_TOKEN_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_OSMOSIS_TOKEN_SUCCESS`,
  FETCH_AB_TEST_FLAVOR_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_AB_TEST_FLAVOR_SUCCESS`,
  FETCH_ALL_USER_COURSE_SECTIONS_SUCCESS: `${reduxStateDomains.STUDENTSTUDY_STATE}/FETCH_ALL_USER_COURSE_SECTIONS_SUCCESS`
};

const restoreState = (state) => ({ type: studentStudyActionType.RESTORE_STATE, payload: state });

export const resetState = () => (dispatch) => {
  dispatch({
    type: studentStudyActionType.RESET_STORE,
    payload: null
  });
};

export const resetStateOnLaunch = () => (dispatch) => {
  dispatch({
    type: studentStudyActionType.RESET_STATE_ON_LAUNCH,
    payload: null
  });
};
const trackAction = (props: AnalyticsActionProps) => (dispatch, getState) => {
  const defaultActionProps = studySelectors.getDefaultActionProps(getState());
  const defaultChatBotActionProps = IncludeAiChatPropsMap[props.action] ? studySelectors.getDefaultChatBotActionProps(getState()) : {};
  const actionProps = scrubProps(props.props);
  const _props = {
    ...defaultActionProps,
    ...defaultChatBotActionProps,
    ...actionProps
  };
  ELSLoggingService.info(fileName, `New Relic PageAction: ${props.action}, ${JSON.stringify(_props)}`);
  if (!window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(props.action, _props);
};

const trackAdobeAction = (props: AdobeAnalyticsActionProps) => (dispatch, getState) => {
  const defaultPageData = studySelectors.getDefaultAdobeAnalyticsPageData(getState());
  const educationProps = stripNilProps(props.pageData.education);
  const pageData = {
    ...defaultPageData,
    education: {
      ...defaultPageData.education,
      ...educationProps
    }
  };
  ELSAdobeAnalyticService.trackEvent(props.action, pageData);
};

const handleRequestError = (dispatch, getState) => (
  error: Error,
  action: string,
  isTrackRequestCount = true
) => {
  const url = get(error, 'config.url', '');
  const method = get(error, 'config.method', '');
  const status = get(error, 'response.status', '');
  if (status === 400) {
    // Likely 400 error here due to overloaded headers
    removeCookies();
  }

  trackAction({
    action: AnalyticsAction.API_ERROR,
    props: {
      status,
      action,
      url,
      method
    }
  })(dispatch, getState);

  dispatch({
    type: studentStudyActionType.REQUEST_ERROR,
    payload: {
      error,
      isTrackRequestCount
    },
  });
  return Promise.reject(error);
};

const fetchCourseSection = (
  courseSectionId: string,
  useCache = false
) => (dispatch, getState): Promise<CourseSectionDto> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }
  if (useCache) {
    const courseSection = studySelectors.getCourse(getState());
    if (courseSection) {
      return Promise.resolve(courseSection);
    }
  }
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return fetchCourseSectionService(courseSectionId)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_COURSE_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseSection'));
};

const fetchAssessmentsByAssignmentIdAndUserIdAction = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssessmentsByAssignmentIdAndUserId(userId, assignmentId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentsByAssignmentIdAndUserIdAction'));
};

const fetchRecentAssessmentOfAssignment = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssessmentByAssignmentIdAndUserIdService(userId, assignmentId)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRecentAssessmentOfAssignment'));
};

const fetchUsers = (courseSectionIds: string[], roleId: string, userId: string, useCache = false) => (dispatch, getState): Promise<UserDto[]> => {

  if (useCache) {
    const users = studySelectors.getUsers(getState());
    if (users && users.length > 0) {
      return Promise.resolve(users);
    }
  }

  dispatch({ type: studentStudyActionType.REQUEST_START, });
  if (roleId === ELSCommonUIConstants.userType.Instructor) {
    return fetchUsersService(courseSectionIds)
      .then((users: UserDto[]) => {
        dispatch({
          type: studentStudyActionType.FETCH_USERS_SUCCESS,
          payload: users,
        });
        return users;
      })
      .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));
  }

  return fetchUserService(userId)
    .then((user: UserDto) => {
      dispatch({
        type: studentStudyActionType.FETCH_USERS_SUCCESS,
        payload: [user],
      });
      return [user];
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));

};

const getUpdatedPropsOnly = (existingCourse: LiteCourseSectionDto, propsToUpdate: Partial<LiteCourseSectionDto>): Partial<LiteCourseSectionDto> => {
  const ret = { ...propsToUpdate };
  Object.keys(propsToUpdate).forEach((key) => {
    if (existingCourse[key] === propsToUpdate[key]) {
      delete ret[key];
    }
  });
  return ret;
};

const updateCourseSection = (
  courseSectionId: string,
  updatedCourseInfo: Partial<LiteCourseSectionDto>,
) => (dispatch, getState): Promise<LiteCourseSectionDto> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchCoursePlanningCourseSectionService(courseSectionId)
    .then(response => {
      const { etag } = response.headers as { etag: string };
      const _updatedCourseInfo = getUpdatedPropsOnly(response.data, updatedCourseInfo);
      return updateCourseSectionService(courseSectionId, _updatedCourseInfo, etag).then((data) => {
        dispatch({
          type: studentStudyActionType.POST_COURSE_SUCCESS,
          payload: data,
        });
        return data;
      });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'updateCourseSection'));
};

// In the future we should consider retrieving this value from the token always
// For pilot and for development we need to separate these values from the token
// If we can reliably get pilot isbns into the token through evolve then this value should never be duplicated here
// Also note this is called Isbns plural because we can support mashup courses with multiple isbns
const setIsbns = isbn => dispatch => dispatch({ type: studentStudyActionType.SET_ISBNS, payload: isbn });

const setUser = ({ courseId, roleId, userId }: UserParams) => dispatch => dispatch(
  {
    type: studentStudyActionType.SET_USER, payload: { courseSectionId: courseId, roleId, userId }
  }
);

const setMessages = (messages: Messages) => dispatch => dispatch({ type: studentStudyActionType.SET_MESSAGES, payload: messages });

const fetchAppLinkData = (
  linkId: string
) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAppLinkDataService(linkId)
    .then((response: AppLinkData) => {
      dispatch({
        payload: response,
        type: studentStudyActionType.FETCH_LINK_DATA_SUCCESS,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAppLinkData'));
};

const getExternalAppRedirectUrlAction = (
  config: AppLinkOutConfig,
  token: string = null
) => () => {
  return getExternalAppRedirectUrl(config, token);
};

const navigateToApp: NavigateToApp = (
  config: AppLinkOutConfig,
  isNewTab = false,
  token: string = null
) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return getExternalAppRedirectUrl(config, token)
    .then(({ redirectUrl }) => {

      trackAction({
        action: AnalyticsAction.APP_LINK_OUT,
        props: {
          linkType: config.action,
          srcApp: config.altSrcApp || Application.STUDENT_STUDY,
          targetApp: config.app
        }
      })(dispatch, getState);

      if (isNewTab) {
        window.open(redirectUrl, '_blank');
      } else {
        window.location.href = redirectUrl;
      }
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'navigateToApp'));
};

const returnAppLink = (
  props: {
    linkId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    returnPostBody: any;
  }
) => (dispatch, getState) => {
  const {
    linkId,
    returnPostBody
  } = props;
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return getLinkNavigation({ linkId, returnPostBody })
    .then(({ redirectUrl }) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      window.location.href = redirectUrl;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'returnAppLink'));
};

const fetchUserCourseOwnerRecords = (
  eolsUserHistoryRequest: {
    userId: number;
    courseSectionId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return fetchUserHistoryByCourseByStateKeyService({
    ...eolsUserHistoryRequest,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER
  })
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: studentStudyActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
          payload: [],
        });
        return [];
      }
      return Promise.reject(error);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchUserCourseOwnerRecords');
    });
};

const fetchUserHistoryByCourseByStateKey = (
  eolsUserHistoryRequest: {
    userId: number;
    stateKey: CoursewareUserHistoryStateKey;
    courseSectionId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return fetchUserHistoryByCourseByStateKeyService(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_USER_HISTORY_SUCCESS,
        payload: { response, stateKey: eolsUserHistoryRequest.stateKey },
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: studentStudyActionType.FETCH_USER_HISTORY_SUCCESS,
          payload: { response: [], stateKey: eolsUserHistoryRequest.stateKey },
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'fetchUserHistoryByCourseByStateKey');
    });
};

const deleteUserHistoryWithUserId = (
  eolsUserHistoryRequest: {
    userId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return deleteUserHistoryByUserId(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.DELETE_USER_HISTORY_SUCCESS,
        payload: { response },
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: studentStudyActionType.DELETE_USER_HISTORY_SUCCESS,
          payload: { response: [] },
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'deleteUserHistoryByUserId');
    });
};

const setIsCourseOwner = (
  eolsUserId: number,
  courseSectionId: string,
  isCourseOwner: string,
  eolsUserHistoryDto: Partial<EolsUserHistoryResponseDto> = {},
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return postUserHistoryService({
    ...eolsUserHistoryDto,
    stateInfo: isCourseOwner,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER,
    eolsUser: {
      id: eolsUserId
    },
    courseSectionId: parseInt(courseSectionId, 10),
  })
    .then((response) => {
      dispatch({
        type: studentStudyActionType.SET_IS_COURSE_OWNER_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'setIsCourseOwner'));
};

const postUserHistory = (
  eolsUserHistoryDto: EolsUserHistoryDto,
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return postUserHistoryService(eolsUserHistoryDto)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.POST_USER_HISTORY_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postUserHistory'));
};

const postGroupFeatureFlagAction = (eolsApp: string, featureName: string, group: string, featureValue: string): Promise<FeatureFlagDto> => {
  return postGroupFeatureFlag(eolsApp, featureName, group, featureValue).then(() => {
    return {
      createdAt: null,
      eolsApp,
      featureName,
      featureValue,
      group,
      updatedAt: null
    };
  });
};

const fetchABFlavor = (abTestFlag: FeatureFlagsGroupedDto) => (dispatch, getState) => {
  const abTestFlavors = studySelectors.getABTestFlavors(getState());
  const abTestFlavor = abTestFlavors.find((flag) => {
    return flag.featureName === abTestFlag.featureName;
  });
  if (abTestFlavor) {
    return Promise.resolve(abTestFlavor);
  }
  const courseSectionId = studySelectors.getCourseSectionId(getState());
  dispatch({ type: studentStudyActionType.REQUEST_START });
  const app = `SHER_EVOL_${abTestFlag.featureName}`;
  const key = abTestFlag.featureName;
  return fetchGroupFeatureFlag(app, key, courseSectionId)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        const newFlavor = getRandomABTestFlavor(abTestFlag);
        if (!newFlavor) {
          ELSLoggingService.error(fileName, `AB test config missing for flag: ${key}`);
          return Promise.resolve({
            createdAt: null,
            eolsApp: app,
            featureName: key,
            featureValue: null,
            group: courseSectionId,
            updatedAt: null
          });
        }
        return postGroupFeatureFlagAction(app, key, courseSectionId, newFlavor);
      }
      return Promise.reject(error);
    })
    .then((response) => {
      dispatch({ payload: response, type: studentStudyActionType.FETCH_AB_TEST_FLAVOR_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchABFlavor'));
};

const fetchAllAppFeatureFlags = () => (dispatch, getState) => {
  const featureFlagsGrouped = studySelectors.getFeatureFlagsGrouped(getState());
  if (featureFlagsGrouped) {
    return Promise.resolve(featureFlagsGrouped);
  }
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchGroupedFeatureFlags().then((response) => {
    dispatch({
      payload: response,
      type: studentStudyActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS,
    });

    const courseSectionId = studySelectors.getCourseSectionId(getState());

    const promises = [];
    const activeABTests = getActiveABTestFlags(response, courseSectionId);

    if (activeABTests && activeABTests.length) {
      activeABTests.forEach((activeABTest) => {
        promises.push(fetchABFlavor(activeABTest)(dispatch, getState));
      });
    }

    if (promises.length) {
      return Promise.all(promises).then(() => {
        return response;
      });
    }

    return response;
  })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAllAppFeatureFlags'));
};

const fetchTaxonomy = (taxonomyId: string) => (dispatch, getState): Promise<RecTaxonomyNodeDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchTaxonomyService(taxonomyId)
    .then((data) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchTaxonomy'));
};

const openVitalSource = (
  isbn: string,
  page: number
) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchVitalSource({
    isbn,
    page,
  })
    .then(vitalSource => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return window.open(vitalSource.signInUrl, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'openVitalSource'));
};

const fetchGroupActivity = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchResourceInfo(vtwId)
    .then(response => {
      dispatch({
        payload: response as GroupActivityDto,
        type: studentStudyActionType.FETCH_GROUP_ACTIVITY_SUCCESS
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchGroupActivity'));
};

const fetchAssessmentSubmissions = (
  assignmentId: string,
  isTrackRequestCount = true
) => (dispatch, getState): Promise<AssessmentSubmissionDto[]> => {
  if (isTrackRequestCount) {
    dispatch({ type: studentStudyActionType.REQUEST_START });
  }
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((assessmentSubmissions) => {
      const payload = { assignmentId, assessmentSubmissions, isTrackRequestCount };
      dispatch({ payload, type: studentStudyActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS });
      return assessmentSubmissions;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissions', isTrackRequestCount));
};

const fetchAllAssessmentSubmissionsAction = (
  assignmentIds: string[],
  isTrackRequestCount = true
) => (dispatch, getState): Promise<Record<string, AssessmentSubmissionDto[]>> => {
  if (isTrackRequestCount) {
    dispatch({ type: studentStudyActionType.REQUEST_START });
  }
  return Promise.all(
    assignmentIds.map((assignmentId) => {
      return fetchAssessmentSubmissionsService(assignmentId)
        .then((assessmentSubmissions) => {
          return {
            assignmentId,
            assessmentSubmissions
          };
        });
    })
  )
    .then((assessmentSubmissionsResponses) => {
      const payload = {
        assessmentSubmissionsMap: getAssessmentSubmissionsMap(assessmentSubmissionsResponses),
        isTrackRequestCount
      };
      dispatch({ payload, type: studentStudyActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS });
      return payload.assessmentSubmissionsMap;
    })
    .catch((e) => {
      return handleRequestError(dispatch, getState)(e, 'fetchAllAssessmentSubmissionsAction', isTrackRequestCount);
    });
};

const fetchAssignments = (
  courseSectionId: string,
) => (dispatch, getState): Promise<AssignmentDto[]> => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssignmentsService(courseSectionId)
    .then((assignments) => {
      const payload = { assignments };
      dispatch({ payload, type: studentStudyActionType.FETCH_ASSIGNMENTS_SUCCESS });
      return Promise.resolve(assignments);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignments'));
};

const fetchEolsUser = (userId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchUserService(userId)
    .then((data) => {
      dispatch({ payload: data, type: studentStudyActionType.FETCH_EOLS_USER_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEolsUser'));
};

const fetchEvolveUsers = (userId: string, useCache = false) => (dispatch, getState) => {
  if (useCache) {
    const evolveUser = studySelectors.getEvolveUser(getState());
    if (evolveUser) {
      return Promise.resolve(evolveUser);
    }
  }
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      dispatch({ payload: crosswalkUser, type: studentStudyActionType.FETCH_EVOLVE_USER_SUCCESS });
      return crosswalkUser;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveUsers'));
};

const fetchCrosswalkUsers = (userId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      return Promise.all(crosswalkUser.eolsUserIdsByUserNameAndType.map(eolsUserIdByUserNameAndType => {
        return fetchUserService(eolsUserIdByUserNameAndType.toString());
      }));
    })
    .then(users => dispatch({ payload: users, type: studentStudyActionType.FETCH_CROSSWALK_USERS_SUCCESS }))
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCrosswalkUsers'));
};

const putAssignments = (assignments: AssignmentDto[]) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  const requests = assignments.map(assignment => putAssignmentService(assignment));
  return Promise.all(requests)
    .then(() => {
      const assignmentsDictionary = keyBy(assignments, 'id');
      dispatch({ payload: assignmentsDictionary, type: studentStudyActionType.PUT_ASSIGNMENTS_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putAssignments'));
};

const setAppLinkCookies = (props: {
  token: string;
  linkId: string;
}) => (dispatch) => {
  dispatch({ payload: props, type: studentStudyActionType.SET_APP_LINK_COOKIES });
};

const updateCollapsedFolderIds = (props: string[], userId: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({ payload: props, type: studentStudyActionType.UPDATE_COLLAPSED_FOLDER_IDS });

  const eolsUserHistoryDTO = {
    eolsUser: {
      id: parseInt(userId, 10)
    },
    stateKey: CoursewareUserHistoryStateKey.COLLAPSED_FOLDERS,
    courseSectionId: parseInt(courseSectionId, 10),
    stateInfo: JSON.stringify(props)
  };

  return fetchUserHistoryByCourseByStateKeyService({
    userId: eolsUserHistoryDTO.eolsUser.id,
    stateKey: eolsUserHistoryDTO.stateKey,
    courseSectionId: eolsUserHistoryDTO.courseSectionId
  })
    .then((response: EolsUserHistoryResponseDto[]) => {
      return response.sort((a, b) => {
        return a.id - b.id;
      }).find((userHistory) => {
        return userHistory.stateKey === eolsUserHistoryDTO.stateKey;
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return {} as EolsUserHistoryResponseDto;
      }
      return Promise.reject(error);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserHistoryByCourseByStateKeyService', false))
    .catch(() => {
      return {} as EolsUserHistoryResponseDto;
    })
    .then((userHistory) => {
      return postUserHistoryService({
        ...userHistory,
        ...eolsUserHistoryDTO
      });
    });
};

const setStoreProps = (props: Partial<StudentStudyStore>) => (dispatch) => {
  dispatch({ payload: props, type: studentStudyActionType.SET_STORE_PROPS });
};

const setEnableDeepLink = (enableDeepLink: boolean) => (dispatch) => {
  dispatch({ payload: enableDeepLink, type: studentStudyActionType.SET_ENABLE_DEEP_LINK });
};

const fetchUserEngagementReport = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });

  return fetchUserEngagementReportService(courseSectionId)
    .then((response: EolsUserEngagementDto[]) => {
      dispatch({
        payload: response,
        type: studentStudyActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserEngagementReport'));
};

const fetchHashLinkAction = (hashLink: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchHashLink(hashLink)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchHashLinkAction'));
};

const fetchDeepLinkDataAction = (deepLinkGuid: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchDeepLinkData(deepLinkGuid, courseSectionId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchDeepLinkDataAction'));
};

const postDeepLinkAction = (data: DeepLinkPostDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postDeepLink(data)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postDeepLinkAction'));
};

const fetchAssignmentAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssignment(assignmentId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentAction'));
};

const fetchUserCourseSectionsAction = (userId: string, active = true, entitled?: boolean) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchUserCourseSections(userId, active, entitled)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserCourseSectionsAction'));
};

const fetchAnalyticsContextAction = (analyticsRequestDto: LearningAnalyticsRequestDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAnalyticsContext(analyticsRequestDto)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAnalyticsContextAction'))
    .catch(() => null);
};

const fetchLessonTopicsAction = (lessonId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchLessonTopics(lessonId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchLessonTopicsAction'));
};

const fetchAssessmentSubmissionsAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissionsAction'));
};

const fetchAssignmentResultsAction = (assignmentId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssignmentResults(assignmentId)
    .then((response) => {
      dispatch({ type: studentStudyActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentResultsAction'));
};

const fetchStudentStudyAssessmentsAction = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START, });
  return fetchStudentStudyAssessments(courseSectionId)
    .then(response => {
      dispatch({
        type: studentStudyActionType.FETCH_STUDENT_STUDY_ASSESSMENTS_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchStudentStudyAssessmentsAction'));

};

const setRegisteredToken = (props: string) => (dispatch) => {
  dispatch({ payload: props, type: studentStudyActionType.SET_REGISTERED_TOKEN });
};

const fetchOsmosisTokenAction = () => (dispatch, getState) => {

  const token = studySelectors.getOsmosisToken(getState());
  if (!shouldGetNewOsmosisToken(token)) {
    return Promise.resolve(token);
  }

  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchOsmosisToken()
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_OSMOSIS_TOKEN_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchOsmosisTokenAction'));
};

const fetchChatEntriesAction = (chatId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEntries(chatId)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEntriesAction'));
};

const fetchChatEvaluationsAction = (chatId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEvaluations(chatId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEvaluationsAction'));
};

const fetchChatsAction = () => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChats()
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatsAction'));
};

const fetchChatEntryAction = (chatId: number, entryId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEntry(chatId, entryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEntryAction'));
};

const fetchAndPostProcessChatEntryReferencesAction = (botEntryId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAndPostProcessChatEntryReferences(botEntryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAndPostProcessChatEntryReferencesAction'));
};

const fetchAndPostProcessChatEntryReferencesV2Action = (botEntryId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAndPostProcessChatEntryReferencesV2(botEntryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAndPostProcessChatEntryReferencesV2Action'));
};

const putPostProcessChatEntryReferencesAction = (botEntryId: number, promptOverride: ChatEntryDto['promptOverride']) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return putPostProcessChatEntryReferences(botEntryId, promptOverride)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putPostProcessChatEntryReferences'));
};

const fetchChatEntryTracesAction = (chatId: number, entryId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEntryTraces(chatId, entryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEntryTracesAction'));
};

const postChatAction = (chat: Omit<ChatDto, 'id'>) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postChat(chat)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postChatAction'));
};

const createDehydratedDto = (entry: Omit<ChatEntryDto, 'id'>): DehydratedChatEntryDto => ({
  message: entry.message,
  searchFilter: entry.searchFilter,
  traces: entry.traces,
  ...(entry.promptOverride && { promptOverride: entry.promptOverride })
});

const postChatEntryAction = (entry: Omit<ChatEntryDto, 'id'>) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  const dtoToPost = createDehydratedDto(entry);
  return postChatEntry(dtoToPost, entry.chatId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postChatEntryAction'));
};

const postChatPromptAction = (entry: Omit<ChatEntryDto, 'id'>) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  const dtoToPost = createDehydratedDto(entry);
  return postChatPrompt(dtoToPost, entry.chatId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postChatPromptAction'));
};

const postChatEntryShellsAction = (entry: DehydratedChatEntryDto, chatId: number) => (dispatch, getState) => {
  // const dtoToPost = createDehydratedDto(entry);
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postChatEntryShells(entry, chatId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postChatEntryShellsAction'));
};

const putChatAction = (entry: ChatDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return putChat(entry)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putChatAction'));
};

const putChatEvaluationAction = (chatId: number, evaluation: ChatEntryEvaluationDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return putChatEvaluation(chatId, evaluation)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putChatEvaluationAction'));
};

const postChatEvaluationAction = (chatId: number, evaluation: Partial<ChatEntryEvaluationDto>) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postChatEvaluation(chatId, evaluation)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postChatEvaluationAction'));
};

const fetchChatPerformanceAction = (startDate: string, numOfDays: number, courseSections: number[]) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatPerformance(startDate, numOfDays, courseSections)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatPerformanceAction'));
};

const postStudentThemesAction = (startDate: string, numOfDays: number, courseSections: number[]) => (dispatch, getState) => {
  return postStudentThemes(startDate, numOfDays, courseSections)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postStudentThemesAction'));
};

const fetchStudentThemesStatusAction = (startDate: string, numOfDays: number, courseSections: number[]) => (dispatch, getState) => {
  return fetchStudentThemesStatus(startDate, numOfDays, courseSections)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchStudentThemesStatusAction'));
};

const fetchAllUserCourseSectionsAction = () => (dispatch, getState) => {

  const allUserCourseSections = studySelectors.getAllUserCourseSections(getState());

  if (allUserCourseSections) {
    return Promise.resolve(allUserCourseSections);
  }

  dispatch({ type: studentStudyActionType.REQUEST_START });
  const userId = studySelectors.getUserId(getState());

  return fetchEvolveUsers(userId)(dispatch, getState)
    .then((crossWalkUser) => {
      return Promise.all(
        crossWalkUser.eolsUserIdsByUserNameAndType.map((_userId) => {
          return fetchUserCourseSectionsAction(_userId.toString())(dispatch, getState);
        })
      );
    })
    .then((responses) => {
      return responses.reduce((acc, courseSections) => {
        return [
          ...acc,
          ...courseSections
        ];
      }, []);
    })
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_ALL_USER_COURSE_SECTIONS_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAllUserCourseSectionsAction'));
};

const fetchRemediationRecommendationsAction = (assessmentId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchRemediationRecommendations(assessmentId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRemediationRecommendationsAction'));
};

const fetchRemediationRecommendationAttemptsAction = (remediationRecommendationIds: number[]) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchRemediationRecommendationAttempts(remediationRecommendationIds)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRemediationRecommendationAttemptsAction'));
};

const postRemediationRecommendationAttemptAction = (recommendationAttempt: Partial<RecommendationAttemptDto>) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postRemediationRecommendationAttempt(recommendationAttempt)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postRemediationRecommendationAttemptAction'));
};

const postRaaSQuizSessionAction = (quizSessionDto: NewQuizSessionDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return postRaaSQuizSession(quizSessionDto)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postRaaSQuizSessionAction'));
};

const fetchQuizSessionByRecommendationAttemptIdAction = (remediationRecommendationAttemptId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchQuizSessionByRecommendationAttemptId(remediationRecommendationAttemptId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchQuizSessionByRecommendationAttemptIdAction'));
};

const fetchRemediationAssignmentAction = (remediationAssignmentId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchRemediationAssignment(remediationAssignmentId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRemediationAssignmentAction'));
};

const fetchRemediationRecommendationItemsAction = (remediationRecommendationId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchRemediationRecommendationItems(remediationRecommendationId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRemediationRecommendationItemsAction'));
};

const fetchAssessmentByIdAction = (userId: string, assessmentId: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchAssessmentById(userId, assessmentId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentById'));
};

const adminSearchVectorAction = (body: VectorSearchDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminSearchVector(body)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminSearchVectorAction'));
};

const adminPromptAction = (body: LlmPromptDto) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminPrompt(body)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminPromptAction'));
};

const fetchChatEeoIsbnsAction = () => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEeoIsbns()
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEeoIsbnsAction'));
};

const fetchChatEntitlementsAction = () => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchChatEntitlements()
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchChatEntitlementsAction'));
};

const fetchPrimaryTaxonomyAction = (isbn: string) => (dispatch, getState) => {
  const allPrimaryTaxonomies = studySelectors.getPrimaryTaxonomies(getState());

  if (allPrimaryTaxonomies && (allPrimaryTaxonomies[isbn] || allPrimaryTaxonomies[isbn] === null)) {
    return Promise.resolve(allPrimaryTaxonomies[isbn]);
  }

  dispatch({ type: studentStudyActionType.REQUEST_START });
  return fetchPrimaryTaxonomy(isbn)
    .then((response) => {
      dispatch({
        type: studentStudyActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS,
        payload: { isbn, response }
      });
      return response;
    })
    .catch((e) => {
      if (e.response && e.response.status === 404) {
        dispatch({
          type: studentStudyActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS,
          payload: { isbn, response: null }
        });
        return null;
      }
      return Promise.reject(e);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchPrimaryTaxonomyAction');
    });

};

const adminFetchChatEntryAction = (entryId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminFetchChatEntry(entryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminFetchChatEntryAction'));
};

const adminFetchChatEntriesAction = (chatId: string, page: number, pageSize: number) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminFetchChatEntries(chatId, page, pageSize)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminFetchChatEntriesAction'));
};

const adminFetchChatEntryTracesAction = (entryId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminFetchChatEntryTraces(entryId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminFetchChatEntryTracesAction'));
};
const adminFetchChatAction = (chatId: string) => (dispatch, getState) => {
  dispatch({ type: studentStudyActionType.REQUEST_START });
  return adminFetchChat(chatId)
    // eslint-disable-next-line sonarjs/no-identical-functions
    .then((response) => {
      dispatch({
        type: studentStudyActionType.REQUEST_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'adminFetchChatEntryTracesAction'));
};

export const studyActions = {
  deleteUserHistoryByUserId: deleteUserHistoryWithUserId,
  fetchAppLinkData,
  fetchAssessmentSubmissions,
  fetchRecentAssessmentOfAssignment,
  fetchAssignments,
  fetchCourseSection,
  fetchCrosswalkUsers,
  fetchEolsUser,
  fetchEvolveUsers,
  fetchTaxonomy,
  fetchUserCourseOwnerRecords,
  fetchUsers,
  fetchUserHistoryByCourseByStateKey,
  fetchStudentStudyAssessmentsAction,
  navigateToApp,
  openVitalSource,
  postUserHistory,
  putAssignments,
  resetState,
  resetStateOnLaunch,
  restoreState,
  setIsbns,
  setIsCourseOwner,
  setMessages,
  setStoreProps,
  setUser,
  updateCourseSection,
  fetchAllAppFeatureFlags,
  setAppLinkCookies,
  returnAppLink,
  updateCollapsedFolderIds,
  trackAction,
  fetchUserEngagementReport,
  fetchGroupActivity,
  trackAdobeAction,
  setEnableDeepLink,
  fetchHashLinkAction,
  fetchDeepLinkDataAction,
  postDeepLinkAction,
  fetchAssignmentAction,
  fetchUserCourseSectionsAction,
  fetchAnalyticsContextAction,
  fetchLessonTopicsAction,
  fetchAssessmentSubmissionsAction,
  fetchAssignmentResultsAction,
  getExternalAppRedirectUrlAction,
  fetchAssessmentsByAssignmentIdAndUserIdAction,
  fetchAllAssessmentSubmissionsAction,
  setRegisteredToken,
  fetchOsmosisTokenAction,
  fetchChatEntriesAction,
  fetchChatEvaluationsAction,
  fetchChatsAction,
  postChatAction,
  postChatEntryAction,
  postChatPromptAction,
  putChatAction,
  putChatEvaluationAction,
  postChatEvaluationAction,
  fetchChatPerformanceAction,
  postStudentThemesAction,
  fetchStudentThemesStatusAction,
  fetchAllUserCourseSectionsAction,
  postChatEntryShellsAction,
  fetchChatEntryAction,
  fetchChatEntryTracesAction,
  fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationItemsAction,
  fetchAssessmentByIdAction,
  fetchPrimaryTaxonomyAction,
  fetchAndPostProcessChatEntryReferencesAction,
  fetchAndPostProcessChatEntryReferencesV2Action,
  fetchChatEeoIsbnsAction,
  adminSearchVectorAction,
  adminPromptAction,
  fetchChatEntitlementsAction,
  adminFetchChatEntryAction,
  adminFetchChatEntriesAction,
  adminFetchChatEntryTracesAction,
  adminFetchChatAction,
  fetchRemediationAssignmentAction,
  fetchRemediationRecommendationAttemptsAction,
  fetchQuizSessionByRecommendationAttemptIdAction,
  putPostProcessChatEntryReferencesAction,
  postRemediationRecommendationAttemptAction,
  postRaaSQuizSessionAction
};
